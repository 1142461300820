import blogdetail from '../assets/images/blog/blogdetail-1.webp'
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom"; 
import axios from "axios";
import { Helmet } from 'react-helmet-async'

const BlogDetail = () => {
  const { slug } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

 
useEffect(() => {
  axios
    .get(`https://wrenchit.co.in/admin/public/api/post/slug?slug=${slug}`)
    .then((response) => {
      console.log("Full API response:", response.data); // Log the full response
      if (response.data && response.data.post) {
        setBlogData(response.data.post); // Set blog data based on correct structure
      } else {
        setError("Blog data not found.");
      }
    })
    .catch((error) => {
      setError("Failed to fetch blog details.");
      console.error("Error fetching blog details:", error);
    })
    .finally(() => {
      setLoading(false);
    });
}, [slug]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!blogData) return <div>No blog data available.</div>;

  return (
    <div>
         <Helmet>
        <title>{blogData?.meta_title}</title>
        <meta name="description" content={blogData?.meta_description } />
        <meta name="keywords" content={blogData?.meta_keywords} />
        <meta property="og:title" content={blogData?.meta_title } />
        <meta property="og:description" content={blogData?.meta_description } />
        <meta property="og:url" content={`https://wrenchit.co.in/blogs/${slug}`} />
      </Helmet>
    <div className='blog_page_detail'>
    <p className='text-white'>Date: {new Date(blogData?.created_at).toLocaleDateString()}</p>
            <h1 className='text-white text-center'><b>
          {blogData.title}
                </b>
            </h1>
         </div>
         <div className='row text-center m-0 p-0'>
         <img src={blogData.image} className='img_blog_detail'></img>
         </div>
        
         <div className='row m-5 pb-5 px-0'>
         <div className='col-md-3'></div>
         <div className='col-md-6'>
         <h2>
            {blogData.title}
          </h2>
        <div className="blog_details">
        <div
             
             dangerouslySetInnerHTML={{ __html: blogData?.description }}
           />
          </div>
               
        </div>
        

         <div className='col-md-3'></div>
         </div>
    </div>
  )
}

export default BlogDetail