import React from "react";
import key from "../assets/images/Key-up.png";
import ListItem from "../Components/ListItem/ListItem";
import serviceFirstImg from "../assets/images/services/glamorous/Glamourous Detailing 600x380.webp";
import image2 from "../assets/images/services/glamorous/Glamourous why Wrenchit 300x300.webp";
import MetalPolishing from "../assets/images/services/glamorous/CHROME AND METAL POLISHING 28x28.png";
import COMPREHENSIVE from "../assets/images/services/glamorous/COMPREHENSIVE CLEANING 28x28.png";
import DASHBOARD from "../assets/images/services/glamorous/DASHBOARD AND CONSOLE DETAILING 28x28.png";
import CLEANING from "../assets/images/services/glamorous/DEEP CLEANING 28x28.png";
import PROTECTION from "../assets/images/services/glamorous/DETAILING AND PROTECTION 28x28.png";
import ENGINE from "../assets/images/services/glamorous/ENGINE CLEANING 28x28.png";
import LEATHER from "../assets/images/services/glamorous/LEATHER CARE 28x28.png";
import PAINT from "../assets/images/services/glamorous/PAINT CORRECTION 28x28.png";
import COATINGS from "../assets/images/services/glamorous/PROTECTIVE COATINGS 28x28.png";
import THOROUGH from "../assets/images/services/glamorous/THOROUGH CLEANING 28x28.png";
import WHEEL from "../assets/images/services/glamorous/WHEEL AND TIRE CARE 28x28.png";
import ServiceCardItem from "../Components/ServiceCardItem/ServiceCardItem";
import ServicesImages from "../Components/ServicesImages";
import ServicesSidebar from "../Components/sidebar/ServicesSidebar";
import { Link } from "react-router-dom";
import GlamourPricing from "../Components/GlamourPricing";
import { Helmet } from 'react-helmet-async';

function GlamourousDetails() {
  const listItems = [
    "Flexible Packages: Detailing packages tailored to meet the specific needs of your luxury car.",
    "Tailored Techniques: Custom detailing techniques suited to luxury vehicles.",
    "Premium Products: Use of high-quality products that are safe and effective for luxury car materials.",
    "Attention to Detail: Focus on every small detail to ensure a flawless finish.",
  ];

  // Function to generate alt text
  const generateAltText = (title) => title.toLowerCase().replace(/\s+/g, '-');

  const services = [
    {
      icon: "bi-briefcase",
      title: "Thorough Cleaning",
      description:
        "Meticulous washing and decontamination to remove dirt, grime, and contaminants.",
      delay: 100,
      alt: generateAltText("Thorough Cleaning"),
      image: THOROUGH
    },
    {
      icon: "bi-card-checklist",
      title: "Paint Correction",
      description:
        "Polishing to remove swirl marks, scratches, and imperfections, restoring the original shine.",
      delay: 200,
      alt: generateAltText("Paint Correction"),
      image: PAINT
    },
    {
      icon: "bi-clock",
      title: "Protective Coatings",
      description:
        "Application of high-quality wax or ceramic coatings to protect the paintwork and enhance gloss.",
      delay: 300,
      alt: generateAltText("Protective Coatings"),
      image: COATINGS
    },
  ];

  const services2 = [
    {
      icon: "bi-briefcase",
      title: "Deep Cleaning",
      description:
        "Cleaning of all exterior surfaces, including windows, wheels, and trims.",
      delay: 100,
      alt: generateAltText("Deep Cleaning"),
      image: CLEANING
    },
    {
      icon: "bi-card-checklist",
      title: "Wheel and Tire Care",
      description:
        "Detailed cleaning, polishing, and protection of wheels and tires.",
      delay: 200,
      alt: generateAltText("Wheel and Tire Care"),
      image: WHEEL
    },
    {
      icon: "bi-clock",
      title: "Chrome and Metal Polishing",
      description: "Polishing of chrome and metal parts to a brilliant shine.",
      delay: 300,
      alt: generateAltText("Chrome and Metal Polishing"),
      image: MetalPolishing
    },
  ];

  const services3 = [
    {
      icon: "bi-briefcase",
      title: "Comprehensive Cleaning",
      description:
        "Vacuuming and shampooing of carpets and upholstery, cleaning of all interior surfaces.",
      delay: 100,
      alt: generateAltText("Comprehensive Cleaning"),
      image: COMPREHENSIVE
    },
    {
      icon: "bi-card-checklist",
      title: "Leather Care",
      description:
        "Cleaning, conditioning, and protection of leather seats and trims.",
      delay: 200,
      alt: generateAltText("Leather Care"),
      image: LEATHER
    },
    {
      icon: "bi-card-checklist",
      title: "Dashboard and Console Detailing",
      description:
        "Detailed cleaning and polishing of the dashboard, console, and other interior components.",
      delay: 300,
      alt: generateAltText("Dashboard and Console Detailing"),
      image: DASHBOARD
    },
  ];

  const services4 = [
    {
      icon: "bi-briefcase",
      title: "Engine Cleaning",
      description:
        "Careful cleaning of the engine bay to remove dirt and grease.",
      delay: 100,
      alt: generateAltText("Engine Cleaning"),
      image: ENGINE
    },
    {
      icon: "bi-card-checklist",
      title: "Detailing and Protection",
      description:
        "Application of protectants to keep engine components in top condition.",
      delay: 200,
      alt: generateAltText("Detailing and Protection"),
      image: PROTECTION
    },
  ];
  return (
    <>
      <main className="main">
      <Helmet>
        <title>Luxury Car Detailing Services & Paint Correction in Pune  | Wrenchit</title>
        <meta
          name="description"
          content="Wrenchit offers exclusive luxury car detailing services and paint correction in Pune. Get expert interior & exterior care to restore and protect your car’s shine."
        />
        <link rel="canonical" href="https://wrenchit.co.in/glamourous-details" />

        "<meta property="og:title" content="Luxury Car Detailing Services & Paint Correction in Pune  | Wrenchit" />
        <meta property="og:site_name" content="Wrenchit" />
        <meta property="og:url" content="https://wrenchit.co.in/glamourous-details" />
        <meta property="og:description" content="Wrenchit offers exclusive luxury car detailing services and paint correction in Pune. Get expert interior & exterior care to restore and protect your car’s shine." />
        <meta property="og:type" content="business.business" />
        <meta property="og:image" content="https://wrenchit.co.in/static/media/Glamourous%20Detailing%20600x380.fb851d50feb3177227a1.jpg" />
      </Helmet>
        {/* Page Title */}
        <div className="page-title services-banner" data-aos="fade">
          <div className="heading">
            <div className="container">
              <div className="row d-flex justify-content-center text-center">
                <div className="col-lg-8 main-banner">
                  <h1 className="">Glamourous Detailing</h1>
                  <p className="mb-0">Services &gt; Glamourous Detailing</p>
                </div>
              </div>
            </div>
          </div>
          <nav className="secound-banner-sec">
            <div className="container">
              <div className="left-div">
              <p>
                  {" "}
                  Get expert care for your luxury vehicle right at<br/> your doorstep.
                </p>
              </div>
              <div className="Right-div">
              <Link className="btn-getstarted f-right" to="/contact">
                  BOOK NOW
                </Link>
                <img src={key} alt="Key" />
              </div>
            </div>
          </nav>
        </div>
        {/* End Page Title */}

        {/* Service Details Section */}
        <section id="service-details" className="pb-0 service-details section">
          <div className="container">
            <div className="row gy-5">
              <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
               <ServicesSidebar/>
              </div>
              <div
                className="col-lg-8 ps-lg-5 services-des"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                 <h2 className="service_h2 ">Glamourous Detailing</h2>
                <p>
                Enhance and preserve the look and feel of your car with Wrenchit’s Glamourous Detailing service. We use only premium products and meticulous techniques to deliver outstanding results, ensuring your vehicle looks and feels extraordinary. Our service includes cleaning all exterior surfaces, including windows, wheels, and trims. We also specialize in rust and tar removal, as well as minor scratch removal. Experience the pinnacle of luxury car detailing with Wrenchit and drive with confidence and pride.
                </p>
                <img
                  src={serviceFirstImg}
                  alt={generateAltText("Glamourous Detailing Service")}
                  className="img-fluid services-img"
                />
                 <h3 className="mt-2">Why Wrenchit?</h3>
                    <p className="mb-4">
                      Our team of experts is trained to handle luxury vehicles
                      with the utmost care and precision. That's why Wrenchit is
                      the best choice for your luxury car detailing needs!
                    </p>
                <div className="row mt-3">
                  <div className="col-md-6">
                   
                    <ul className="comman-is w-100 mb-4 mb-md-0">
                      {listItems.map((item, index) => (
                        <ListItem key={index} text={item} />
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-6">
                    <div className="comman-issue">
                      <img src={image2} 
                  alt={generateAltText("Glamourous Detailing Why Wrenchit")}
                      
                      className="img-fluid pt-0" />
                    </div>
                  </div>
                </div>
                
              </div>
              {/* Services Section */}
              <main id="services" className="services section1 mt-0">
                  <div className="Benefits BenefitsSection   px-12">
                    <div className="container p-0 mb-4" data-aos="fade-up">
                      <h3>Our Luxurious Car Detailing Services</h3>
                      <p>
                        Our detailing service includes a range of specialized
                        treatments aimed at every aspect of your vehicle.
                      </p>
                    </div>
                    <div className="container p-0">
                      <h4>Paintwork Enhancement</h4>
                      <p className="pb-3">
                        Under paintwork enhancement, we offer the following
                        services:
                      </p>
                      <div className="row g-4 essential_car_services">
                        {services.map((service, index) => (
                          <ServiceCardItem
                            key={index}
                            alt={service.alt}
                            icon={service.icon}
                            title={service.title}
                            description={service.description}
                            delay={service.delay}
                            image={service.image}
                          />
                        ))}
                      </div>
                    </div>

                    <div className="container p-0 pt-lg-4">
                      <hr></hr>
                      <h4 className="mt-5">Exterior Detailing</h4>
                      <p className="pb-3">
                        Wrenchit offers the following Exterior Detailing
                        services:
                      </p>
                      <div className="row g-4 essential_car_services">
                        {services2.map((service, index) => (
                          <ServiceCardItem
                            key={index}
                            alt={service.alt}
                            icon={service.icon}
                            title={service.title}
                            description={service.description}
                            delay={service.delay}
                            image={service.image}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="container p-0 pt-4">
                      <hr></hr>
                      <h4 className="mt-5">Interior Detailing</h4>
                      <p className="pb-3">
                        Our meticulous interior detailing services include the
                        following:
                      </p>
                      <div className="row g-4 essential_car_services">
                        {services3.map((service, index) => (
                          <ServiceCardItem
                            key={index}
                            alt={service.alt}
                            icon={service.icon}
                            title={service.title}
                            description={service.description}
                            delay={service.delay}
                            image={service.image}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="container p-0 py-4 pb-0">
                      <hr></hr>
                      <h4 className="mt-5">Engine Bay Detailing</h4>
                      <p className="pb-3">
                        Our Engine Detailing services keep your engine ready for
                        optimum performance.
                      </p>
                      <div className="row g-4 essential_car_services">
                        {services4.map((service, index , alt) => (
                          <ServiceCardItem
                            key={index}
                            alt={service.alt}
                            icon={service.icon}
                            title={service.title}
                            description={service.description}
                            delay={service.delay}
                            image={service.image}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <p className="mt-5 mb-0 " style={{paddingLeft:'12px',paddingRight:"12px"}}>
                    With Wrenchit’s Glamourous Detailing service, enhance and
                    preserve every detail of your car. Our expert technicians
                    use premium products and meticulous techniques to deliver
                    outstanding results, ensuring your vehicle looks and feels
                    extraordinary. Experience the pinnacle of luxury car
                    detailing with Wrenchit and drive with confidence and pride.
                  </p>
                  {/* <ServicesImages/> */}
                </main>
                {/* End Services Section */}
            </div>
          </div>
        </section>
<div className="" >
<GlamourPricing formId="form_two"/>

</div>
        {/* End Service Details Section */}
      </main>
    </>
  );
}

export default GlamourousDetails;
