import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet-async'
import blog from '../assets/images/blog/blog-1.jpg'
import { Link } from 'react-router-dom'

const Blog = () => {
    const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]); // State to hold the list of blogs
  const [loading, setLoading] = useState(true); // State to manage loading
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get("https://wrenchit.co.in/admin/public/api/all-posts");
        setBlogs(response.data.posts); // Assuming the API returns an object with a posts array
      } catch (error) {
        setError("Failed to fetch blogs.");
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false); // Stop loading regardless of success or failure
      }
    };

    fetchBlogs();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // You could replace this with a spinner or more elegant loading state
  }

  if (error) {
    return <div>{error}</div>; // Show error message if fetching fails
  }

// Function to truncate description to the first 80 words
const truncateDescription = (description) => {
  // Remove HTML tags
  const plainText = DOMPurify.sanitize(description.replace(/<[^>]*>/g, ''));
  const words = plainText.split(/\s+/); // Split by whitespace

  if (words.length > 15) {
    return words.slice(0, 15).join(' ') + '...'; // Add ellipsis if truncated
  }
  return plainText; // Return full description if it's less than or equal to 80 words
};
const recentPosts = blogs.slice(0, 3);

   // Function to format date (YYYY-MM-DD)
   const formatDate = (dateString) => {
    return dateString.split("T")[0]; // Extracts only "YYYY-MM-DD"
  };
  return (
    <div>
          <Helmet>
        <title>Our Blogs    </title>
        <meta
          name="description"
          content="" />
        <link rel="canonical" href="https://wrenchit.co.in/blog" />

        "<meta property="og:title" content="" />
        <meta property="og:site_name" content="Wrenchit" />
        <meta property="og:url" content="" />
         </Helmet>
         <div className='blog_page'>
            <h1><b>
                Our blogs
                </b>
            </h1>
         </div>
         <div className='blog_grid'>
                <div className='container'>
              <div className='row'>
             <div className='col-md-8'>
            <div  className="row mb-4">
                
            {blogs.map(blog => (

                    <div className='col-md-6 mb-5' key={blog.id}>
                        <img src={blog.image || "fallback_image_url.jpg"}  alt={blog.title} 
                    className={`img img-fluid `}></img>
                        <div className='row'>
                        <div className='col-md-8'>
                        <h2>
                        {blog.title}
                        </h2>
                        </div>
                        <div className='col-md-4 p-0 mt-4'>
                        <p>Date: {formatDate(blog.created_at)}</p>
                        </div>
                        </div>

                        <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(truncateDescription(blog.description)), // Truncate description
                      }}
                    />
                    
                        <button className='btn '
                         onClick={() => navigate(`/blogs/${blog.slug}`)}
                        >Read Article</button>
                    </div>
                  ))}

                </div>
             </div>
            <div className='col-md-4'>
                            <div className='recent_post'>
                                <h3 className='mb-4'>Recent Posts</h3>

                                {recentPosts.map((post) => (
                                    <div className='row mb-3' key={post.id}>
                                        <div className='col-md-4'>
                                            <img src={post.image || "fallback_image_url.jpg"} alt={post.title} className='img img-fluid' />
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='latest_blog'>
                                                <h3>{post.title}</h3>
                                                <p>Date: {formatDate(post.created_at)}</p>
                                                <Link to={`/blogs/${post.slug}`} className='readmore_btn'>Read More</Link>
                                            </div>
                                        </div>
                                    </div>
                                ))}
</div>
</div>
             
              </div>
                </div>
         </div>
    </div>
  )
}

export default Blog