import React from "react";
import key from "../../assets/images/Key-up.png";
import ListItem from "../../Components/ListItem/ListItem";
import serviceFirstImg from "../../assets/images/services/master danting/Master Dent 600x380.webp";
import image2 from "../../assets/images/services/master danting/master dent why wrenchit 300x300.webp";
import CLEARCOATAPPLICATION from "../../assets/images/services/master danting/CLEAR COAT APPLICATION.png";
import COSMETIC from "../../assets/images/services/master danting/COSMETIC ENHANCEMENTS.png";
import DENT from "../../assets/images/services/master danting/DENT REPAIR.png";
import BODYREPAINTS from "../../assets/images/services/master danting/FULL BODY REPAINTS.png";
import PAINTBLENDING from "../../assets/images/services/master danting/PAINT BLENDING.png";
import CORRECTION from "../../assets/images/services/master danting/PAINT CORRECTION.png";
import PAINTING from "../../assets/images/services/master danting/SPOT PAINTING.png";
import ServicesImages from "../../Components/ServicesImages";
import ServicesSidebar from "../../Components/sidebar/ServicesSidebar";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
function MasterDentPaintSolutions() {
  const listItems = [
    "Expert Technicians: Highly trained professionals with extensive experience in dent repair and paint services.",
    "Advanced Techniques: Cutting-edge methods and equipment for precise and efficient repairs.",
    "Quality Products: Premium paints and coatings for long-lasting durability and an impeccable finish.",
    "Customer Satisfaction: Commitment to excellence, ensuring your vehicle looks its best and you drive away satisfied.",
  ];
  const generateAltText = (title) => title.toLowerCase().replace(/\s+/g, '-');
  return (
    <main className="main">
         <Helmet>
        <title>Best Car Painting & Dent Repair Services in Pune  | Wrenchit</title>
        <meta
          name="description"
          content="Looking for the best dent and paint services in Pune? Wrenchit provides professional car painting services and dent repair for a flawless, showroom-like finish."
        />
        <link rel="canonical" href="https://wrenchit.co.in/master-dent-and-paint-solutions" />

        "<meta property="og:title" content="Best Car Painting & Dent Repair Services in Pune  | Wrenchit" />
        <meta property="og:site_name" content="Wrenchit" />
        <meta property="og:url" content="https://wrenchit.co.in/master-dent-and-paint-solutions" />
        <meta property="og:description" content="Looking for the best dent and paint services in Pune? Wrenchit provides professional car painting services and dent repair for a flawless, showroom-like finish." />
        <meta property="og:type" content="business.business" />
        <meta property="og:image" content="https://wrenchit.co.in/static/media/Master%20Dent%20600x380.100b895a2cb1b5e8b331.jpg" />
      </Helmet>
      {/* Page Title */}
      <div className="page-title services-banner" data-aos="fade">
        <div className="heading">
          <div className="container">
            <div className="row d-flex justify-content-center text-center">
              <div className="col-lg-8 main-banner">
                <h1 className="">Master Dent & Paint Solutions</h1>
                <p className="mb-0">
                  Services &gt; Master Dent & Paint Solutions
                </p>
              </div>
            </div>
          </div>
        </div>
        <nav className="secound-banner-sec">
          <div className="container">
            <div className="left-div">
            <p>
                  {" "}
                  Get expert care for your luxury vehicle right at<br/> your doorstep.
                </p>
            </div>
            <div className="Right-div">
            <Link className="btn-getstarted f-right" to="/contact">
                  BOOK NOW
                </Link>
              <img src={key} alt="Key" />
            </div>
          </div>
        </nav>
      </div>
      {/* End Page Title */}

      {/* Service Details Section */}
      <section id="service-details" className="service-details section">
        <div className="container">
          <div className="row gy-5">
            <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
             <ServicesSidebar/>
            </div>
            <div
              className="col-lg-8 ps-lg-5 services-des"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h3>Master Dent & Paint Solutions</h3>
              <p>
                At Wrenchit, we provide top-tier dent and paint services
                designed to restore and enhance your vehicle's appearance. Our
                Master Dent & Paint service ensures your car looks flawless,
                whether you're dealing with minor dings or major paint and dent
                repairs. We combine advanced techniques with meticulous
                craftsmanship to deliver exceptional results that meet the
                highest standards.{" "}
              </p>
              <img
                src={serviceFirstImg}
                alt={generateAltText("Master Dent and Paint Solutions Services")}
                className="img-fluid services-img"
              />
               <h3 className="mt-4">Why Wrenchit ?</h3>
                  <p className="mb-4">
                    Choosing Wrenchit for your dent and paint needs means
                    partnering with experts dedicated to delivering superior
                    results. Backed by our state-of-the-art technology, our
                    experienced experts deliver comprehensive dent and paint
                    services and ensure complete customer satisfaction.
                  </p>
              <div className="row mt-3">
                <div className="col-md-6">
                 
                  <ul className="comman-is w-100 mb-5">
                    {listItems.map((item, index) => (
                      <ListItem key={index} text={item} />
                    ))}
                  </ul>
                </div>
                <div className="col-md-6">
                  <div className="comman-issue">
                    <img src={image2}
                alt={generateAltText("Master Dent and Paint Solutions Why Wrenchit")}
                    className="img-fluid pt-0" />
                  </div>
                </div>
              </div>
            
            </div>
              {/* Services Section */}
              <main id="services" className="services section1 pb-0 mt-0 ">
                <div className="Benefits BenefitsSection  px-12">
                  <div className="container p-0 mb-4" data-aos="fade-up">
                    <h2>Our Dent and Paint Services</h2>
                    <p className="pb-2">
                      At Wrenchit, we offer a range of comprehensive dent and
                      paint services tailored to meet your specific needs. Our
                      expert technicians ensure your vehicle receives the best
                      care, restoring its appearance and value.
                    </p>
                  </div>
                  <div className="container p-0">
                    <div className="row  g-3 g-md-4 essential_car_services">
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="100"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                            <img src={BODYREPAINTS} 
                alt={generateAltText("Full Body Repaints")}
                            />
                          </div>
                          <div>
                            <h4 className="title">Full Body Repaints</h4>
                            <p className="description">
                              Revitalize your vehicle with a complete repaint,
                              restoring its original beauty or transforming its
                              look with a fresh, new color.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="200"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                           <img src={PAINTING} 
                alt={generateAltText("Spot Painting")}
                            />
                          </div>
                          <div>
                            <h4 className="title">Spot Painting</h4>
                            <p className="description">
                              Address specific areas of damage or wear with
                              precision spot painting, ensuring a seamless blend
                              with the existing paintwork.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                           <img src={PAINTBLENDING} alt={generateAltText("Paint Blending")} />
                          </div>
                          <div>
                            <h4 className="title">Paint Blending</h4>
                            <p className="description">
                              Achieve a flawless finish by expertly blending new
                              paint with the original, creating a uniform and
                              consistent appearance.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={CLEARCOATAPPLICATION} alt={generateAltText("Clear Coat Application")} />

                          </div>
                          <div>
                            <h4 className="title">Clear Coat Application</h4>
                            <p className="description">
                              Enhance and protect your vehicle's paint with a
                              durable clear coat, adding a glossy finish and
                              shielding against environmental elements.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={CORRECTION} alt={generateAltText("Paint Correction")} />

                          </div>
                          <div>
                            <h4 className="title">Paint Correction</h4>
                            <p className="description">
                              Eliminate imperfections such as swirl marks,
                              scratches, and oxidation with our meticulous paint
                              correction process, restoring a pristine finish.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={COSMETIC} alt={generateAltText("Cosmetic Enhancements")} />

                          </div>
                          <div>
                            <h4 className="title">Cosmetic Enhancements</h4>
                            <p className="description">
                              Boost your vehicle's aesthetic appeal with
                              tailored cosmetic enhancements, from custom paint
                              accents to detailed pinstriping.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="400"
                      >
                        <div className="service-item pt-0 d-flex">
                          <div className="icon flex-shrink-0">
                          <img src={DENT} alt={generateAltText("Dent Repair")} />

                          </div>
                          <div>
                            <h4 className="title">Dent Repair</h4>
                            <p className="description">
                              Our skilled technicians utilize advanced
                              techniques to remove dents and dings without
                              affecting the original paint, ensuring a smooth
                              and flawless surface.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <p className="mt-5 mb-0 mx-2">
        You can definitely trust Wrenchit for all your Dent and Paint needs! Our
        experienced experts are known for the highest level of car care.
        Schedule your dent and paint appointment today and keep your vehicle in
        pristine condition always!
      </p>
      {/* <ServicesImages/> */}
              </main>
              {/* End Services Section */}
          </div>
          
        </div>
        
      </section>
    
      {/* End Service Details Section */}
    </main>
  );
}

export default MasterDentPaintSolutions;
